<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      تكملة البيانات
    </dashboard-page-title>
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-store"></i> معلومات المتجر
                  </h5>
                </template>
                <b-row>
                  <b-col cols="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.store_name_ar"
                      validate="required"
                      name="id"
                      placeholder="الإسم بالعربية"
                      label="أسم المتجر بالعربية"
                    />
                  </b-col>
                  <b-col cols="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.store_name_en"
                      validate="required"
                      name="id"
                      placeholder="الإسم بالإنجليزية"
                      label="أسم المتجر بالإنجليزية"
                    />
                  </b-col>

                  <b-col md="6">
                    <textarea-form
                      placeholder="عن المتجر بالعربية"
                      v-model="market.about_ar"
                      label="عن المتجر بالعربية"
                      name="about"
                      validate="required"
                    ></textarea-form>
                  </b-col>
                  <b-col md="6">
                    <textarea-form
                      placeholder="عن المتجر بالإنجليزية"
                      v-model="market.about_en"
                      label="عن المتجر بالإنجليزية"
                      name="about"
                      validate="required"
                    ></textarea-form>
                  </b-col>

                  <b-col md="6">
                    <textarea-form
                      placeholder="الشروط و الأحكام بالعربية"
                      v-model="market.policies_ar"
                      label="الشروط و الأحكام بالعربية"
                      name="policies"
                      validate="required"
                    ></textarea-form>
                  </b-col>
                  <b-col md="6">
                    <textarea-form
                      placeholder="الشروط و الأحكام بالإنجليزية"
                      v-model="market.policies_en"
                      label="الشروط و الأحكام بالإنجليزية"
                      name="policies"
                      validate="required"
                    ></textarea-form>
                  </b-col>
                  <b-col md="12">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.address"
                      :validate="'required'"
                      name="id"
                      label="العنوان"
                      placeholder="العنوان"
                    />
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-wallet"></i> المعلومات البنكية
                  </h5>
                </template>
                <b-row>
                  <b-col md="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.bank_name"
                      :validate="'required'"
                      name="bank_name"
                      label="أسم البنك"
                      placeholder="أسم البنك"
                    />
                  </b-col>

                  <b-col md="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.bank_iban_no"
                      :validate="'required'"
                      name="bank_iban_no"
                      label="رقم الآيبان"
                      placeholder="رقم الآيبان"
                    />
                  </b-col>
                  <!-- commercial_no -->
                  <b-col md="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.commercial_no"
                      :validate="'required'"
                      name="commercial_no"
                      label="رقم السجل التجارى"
                      placeholder="رقم السجل التجارى"
                    />
                  </b-col>
                  <!-- vat_no -->
                  <b-col md="6">
                    <input-form
                      class="mb-3 joining-label"
                      v-model="market.vat_no"
                      :validate="'required'"
                      name="vat_no"
                      label="رقم البطاقة الضريبية"
                      placeholder="رقم البطاقة الضريبية"
                    />
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-file-alt"></i> روابط المتجر
                  </h5>
                </template>
                <b-row>
                  <b-col
                    md="6"
                    class="mb-2"
                    v-for="(link, key) in links"
                    :key="key"
                  >
                    <input-form
                      :id="`URL Link ${link.name}`"
                      v-model="market.social_media_links[link.name]"
                      :placeholder="`EX: ${link.placeholder} `"
                      :validate="'required'"
                      :label="link.name"
                    />
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-image"></i> الصور
                  </h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-4">
                    <cropper-images
                      v-for="(value, key) in images"
                      :key="key"
                      :label="value.name"
                      nameOfImage="image.jpg"
                      @cropper-save="saveImage($event, key)"
                      :progressLoading="value.progress"
                      :multi="false"
                      :imageUrl="value.image"
                      :ratio="value.ratio"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  حفظ البيانات
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                <b-button variant="primary" class="w-100" type="submit">
                  حفظ
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import settingsServices from "../services/settings";
import mainInfoMixins from "@/mixins/mainInfo";
import InputForm from "../../../components/formElements/InputForm.vue";
export default {
  mixins: [mainInfoMixins],
  components: { InputForm },
  mounted() {
    core.index();
  },
  data() {
    return {
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : { name: "", phone: "", national_id: "" },
      images: {
        logo: {
          name: "شعار المتجر",
          image: "",
          progress: 0,
        },
        cover: {
          name: "غلاف المتجر",
          image: "",
          progress: 0,
          ratio: 3 / 1,
        },
        commercial_registry: {
          name: "السجل التجارى",
          image: "",
          progress: 0,
        },
        vat_certificate: {
          name: "البطاقة الضريبية",
          image: "",
          progress: 0,
        },
      },

      loadingSubmitForm: false,
    };
  },
  computed: {
    links() {
      return [
        {
          name: "website",
          link: this.market.social_media_links.website,
          placeholder: "https://www.example.com",
        },
        {
          name: "facebook",
          link: this.market.social_media_links.facebook,
          placeholder: "https://www.facebook.com/example",
        },
        {
          name: "instagram",
          link: this.market.social_media_links.instagram,
          placeholder: "https://www.instagram.com/example",
        },
        {
          name: "twitter",
          link: this.market.social_media_links.twitter,
          placeholder: "https://www.twitter.com/example",
        },
      ];
    },
  },
  methods: {
    changedData(originalObj, updatedObj) {
      let diffObj = {};
      for (let key in updatedObj) {
        if (
          typeof updatedObj[key] === "object" &&
          originalObj.hasOwnProperty(key) &&
          typeof originalObj[key] === "object" &&
          updatedObj[key] !== null &&
          !Array.isArray(updatedObj[key])
        ) {
          let result = this.changedData(originalObj[key], updatedObj[key]);
          if (Object.keys(result).length > 0) diffObj[key] = result;
          else diffObj[key] = updatedObj[key];
        } else if (originalObj[key] !== updatedObj[key]) {
          diffObj[key] = updatedObj[key];
        }
      }
      return diffObj;
    },
    saveImage(file, name) {
      const formData = new FormData();
      formData.append("image", file.image);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          this.images[name].progress = percent;
        },
      };
      this.commonUploadFiles(formData, options).then((res) => {
        this.market[name] = res.data.url;
        this.images[name].image = res.data.preview;
        this.showSuccessUploadFile();
        this.images[name].progress = 0;
      });
    },

    save() {
      this.loadingSubmitForm = true;
      settingsServices
        .uploadMarketplaceFiles(this.changedData(this.sourceObj, this.market))
        .then((res) => {
          core.showSnackbar("success", "Data Updated Successfully");
          this.getInfo();
        })
        .finally(() => {
          this.loadingSubmitForm = false;
        });
    },
  },
};
</script>
<style>
.main-actions {
  position: sticky !important;
  top: 10px;
}
</style>
